window.addEventListener('load', () => {

  if(!window.eop) { return }

  const postClickLog = (event) => {
    const eopClickEvent = {
      source: "web",
      action: "click",
      contents: event.currentTarget.dataset.eopContents,
      split: "default"
    }
    const eopParams = {...eopClickEvent, ...window.eop.getEnvironment()}

    if(event.target.dataset.eopPage){
      eopParams.page = event.target.dataset.eopPage
    }
    window.eop.postEvent([eopParams], true);
  }

  const targets = document.querySelectorAll('[data-eop-contents]')
  targets.forEach((target) => {
    target.addEventListener('click', (e) => postClickLog(e))
  })
  window.eop.markChildren(document, true);
  window.eop.scan();
})

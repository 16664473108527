// NOTE: 本来はjQueryを剥がしたいが、エムスリー共通で使っているスクリプトと合わせるためにjQueryを使っている
// NOTE: PCログイン/未ログイン、SPログイン時にはs.m3img.com/atlas/1.0.0/javascripts/atlas.global.min.js内でスクロールボタンの制御を行っているためSP未ログイン時のみこのスクリプトが動くようにしている
document.addEventListener('DOMContentLoaded', function () {
  const isPc = !!!(document.querySelector('body.is-mobile'))
  const isAlreadyLoggedIn = m3gaSystemCd != '0'
  if (isPc || isAlreadyLoggedIn) { return }

  const pagetop = $('.m3sp-pagetop');
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  pagetop.click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });
});

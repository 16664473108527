window.addEventListener('load', () => {
  const spFloatHeader = document.getElementsByClassName('js-spFloatHeader')
  if(spFloatHeader.length) {
    const toggleDisplayFloatHeader = (startPosition, scrollTop) => {
      if(startPosition > 0 && scrollTop > startPosition) {
        spFloatHeader[0].classList.add('hide')
      } else {
        spFloatHeader[0].classList.remove('hide')
      }
    }

    let startPosition = 0
    toggleDisplayFloatHeader(startPosition, window.scrollY)

    window.addEventListener('scroll', () => {
      let winScrollTop = window.scrollY
      toggleDisplayFloatHeader(startPosition, winScrollTop)
      startPosition = winScrollTop
    })
  }
});
